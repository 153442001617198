/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Image from '../components/image';
import InternalLink from '../components/internal-link';

const ProductsPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout location={ location } noCta>
            <SEO
                title="Courses and books for making independent income online."
                description="If you're a freelancer, consultant or company of one my products will help you increase your opportunities, leads and revenue."
            />
            <Section narrow>
                <h1>Courses and books for making independent income online</h1>
                <p>If you&apos;re a freelancer, consultant or company of one my products will help you increase your opportunities, leads and revenue.</p>
                <InternalLink
                    className="resource"
                    to="/products/10-steps-to-becoming-a-better-freelancer"
                    key="10-steps-to-becoming-a-better-freelancer"
                >
                    <div className="resource-image">
                        <Image
                            name="10-steps-to-becoming-a-better-freelancer-cover.png"
                            alt="10 Steps To Becoming A Better Freelancer"
                        />
                    </div>
                    <div className="resource-content">
                        <h3>10 Steps To Becoming A Better Freelancer</h3>
                        <p>Real-world methods tested over a decade to improve your freelance fortunes.</p>
                        <p className="resource-author">FREE eBook</p>
                    </div>
                </InternalLink>
                <InternalLink
                    className="resource"
                    to="/products/pricing-freelance-projects"
                    key="pricing-freelance-projects"
                >
                    <div className="resource-image">
                        <Image
                            name="pricing-freelance-projects-cover.png"
                            alt="Pricing Freelance Projects"
                        />
                    </div>
                    <div className="resource-content">
                        <h3>Pricing Freelance Projects</h3>
                        <p>The story of how I priced myself to a six-figure yearly freelance career from scratch.</p>
                        <p className="resource-author">eBook</p>
                    </div>
                </InternalLink>
                <InternalLink
                    className="resource"
                    to="/products/personal-website-playbook"
                    key="personal-website-playbook"
                >
                    <div className="resource-image">
                        <Image
                            name="pwp-square.png"
                            alt="Personal Website Playbook"
                        />
                    </div>
                    <div className="resource-content">
                        <h3>The Personal Website Playbook</h3>
                        <p>How to get more leads, revenue and opportunity from your website.</p>
                        <p className="resource-author">Video course</p>
                    </div>
                </InternalLink>
            </Section>
        </Layout>
    );
};

ProductsPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

ProductsPage.defaultProps = {
    location: {},
};

export default ProductsPage;
